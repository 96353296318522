import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

const PROVIDER = 'https://trainingprovider.evipscloud.com';
const LAST_PATH_KEY = 'last path';

interface IContentFrameProps {}
export function ContentFrame({}: IContentFrameProps) {
  const ref = useRef<any>();

  useEffect(() => {
    function onMessage(event: MessageEvent) {
      const pName = event.data;
      const lastPath = localStorage.getItem(LAST_PATH_KEY);

      if (lastPath === pName) return;
      if (typeof pName !== 'string') {
        console.log(event.data);
        return;
      }
      window.history.pushState({}, '', event.data);
      localStorage.setItem(LAST_PATH_KEY, pName);
    }
    function onLocationChange(event: PopStateEvent) {
      const pName = window.location.pathname + window.location.search;

      ref.current.src = PROVIDER + pName;
    }
    window.addEventListener('message', onMessage);
    window.addEventListener('popstate', onLocationChange);
    return () => {
      window.removeEventListener('popstate', onLocationChange);
      window.removeEventListener('message', onMessage);
    };
  }, []);

  return (
    <StyledContentFrame
      ref={ref}
      src={PROVIDER + window.location.pathname + window.location.search}
      frameBorder="0"
      //   sandbox="true"
    ></StyledContentFrame>
  );
}

const StyledContentFrame = styled.iframe`
  width: 100vw;
  height: calc(100vh - 58px);
`;
