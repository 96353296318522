import React, { useState } from 'react';
import { hooks, Header } from '@engelvoelkersamericas/common-story-ui';
import { ContentFrame } from './components/ContentFrame';
import darkIcon from './assets/darkHelp.svg';

const { GlobalProvider, ProfileProvider } = hooks;

function App() {
  return (
    <GlobalProvider>
      <ProfileProvider>
        <Header
          showSearch={false}
          showSupport={false}
          productName='Help'
          productLogo='/global/static/icons/ev/help.svg'
          logoLink='/'
          darkModeLogo={darkIcon}
          cmsCourseId={4}
        />

        <ContentFrame />
      </ProfileProvider>
    </GlobalProvider>
  );
}

export default App;
